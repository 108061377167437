.dashboardContent .headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
}

.dashboardContent .headingContainer .achievementsHeading {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.dashboardContent .headingContainer .achievementsHeading h2 {
  margin-bottom: 0;
}

.dashboardContent .headingContainer .achievementsHeading p {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primaryColor);
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: rgba(73, 144, 226, 0.1);
}

.dashboardContent .headingContainer .progressBarContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 300px;
  width: 100%;
}

.dashboardContent .headingContainer .progressBarContainer span {
  font-size: 1rem;
  font-weight: 400;
  color: #858585;
}

.dashboardContent .headingContainer .progressBarContainer .progressBar {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #e6e6e6;
}

.dashboardContent
  .headingContainer
  .progressBarContainer
  .progressBar
  .progressFill {
  height: 100%;
  width: 0%;
  border-radius: 10px;
  background-color: var(--primaryColor);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  animation: progress 1s linear;
}

@keyframes progress {
  0% {
    width: 0%;
  }
}

.dashboardContent .achievementsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 1rem;
}

.dashboardContent .achievementsContainer .achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  border: solid 1px #858585;
  width: 100%;
}

.dashboardContent .achievementsContainer .achievement h4 {
  font-size: 2rem;
  font-weight: 700;
}

.badgesList {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
}

.badgesList .badgeRow {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.badgesList .badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 1px #858585;
  width: 100%;
}

.badgesList .badge img {
  width: 100px;
  height: 100px;
}

.badgesList .badge .badgeInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.badgesList .badge .badgeInfo h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.badgesList .badge .badgeInfo p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #858585;
}

.badgesList .badge.locked {
  background-color: #dcdcdc;
}

.badgesList .badge.locked img {
  filter: grayscale(100%);
}

.badgesList .badge.locked .badgeInfo h4 {
  color: #858585;
}

@media screen and (max-width: 1200px) {
  .dashboardContent .headingContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboardContent .achievementsContainer {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .dashboardContent .achievementsContainer .achievement {
    width: 48%;
    padding: 0.75rem 1rem;
  }
  .dashboardContent .achievementsContainer .achievement h4 {
    font-size: 1.75rem;
  }
  .badgesList .badge {
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 992px) {
  .badgesList .badge .badgeInfo h4 {
    font-size: 1rem;
  }
  .badgesList .badgeRow {
    flex-direction: column;
  }
  .badgesList .badge {
    flex-direction: row;
  }
  .badgesList .badge .badgeInfo p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 576px) {
  .dashboardContent .achievementsContainer .achievement h4 {
    font-size: 1.5rem;
  }
  .dashboardContent .achievementsContainer .achievement p {
    font-size: 0.8rem;
  }
}