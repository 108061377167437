form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form .formRow {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

form .formRow .formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

form .formGroup.half {
  width: 50%;
  padding-right: 0.5rem;
}

form .formGroup label {
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
}

form .formGroup label span {
  color: #969696;
  padding-left: 0.25rem;
}

form .formGroup input,
form .formGroup textarea {
  font-size: 0.9rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: black;
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  width: 100%;
  transition: border-color 0.3s ease-in-out;
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  -ms-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
}

form .formGroup input.disabled,
form .formGroup textarea.disabled {
  background-color: #ececec;
  cursor: not-allowed;
}

form .formGroup textarea {
  font-family: "Inter", sans-serif;
  resize: vertical;
  outline: none;
  min-height: 100px;
}

form .formGroup .passwordField input {
  width: 100%;
  padding-right: 3rem;
}

form .formGroup .passwordField svg {
  position: absolute;
  margin-left: -2.25rem;
  margin-top: 8px;
  width: 1.25rem;
  height: auto;
  cursor: pointer;
}

form .formGroup input:focus,
form .formGroup textarea:focus {
  outline: none;
  border-color: var(--primaryColor);
}

form .formGroup input.error,
form .formGroup textarea.error {
  border-color: var(--errorColor);
}

form .formGroup .fieldErrorMsg {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--errorColor);
}

form .checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

form .checkbox input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-top: 1px;
}

@media (max-width: 992px) {
  form .formRow {
    flex-direction: column;
  }
  form .formGroup.half {
    width: 100%;
    padding-right: 0;
  }
}
