.dashboardHeaderContainer {
  background-image: url("../../public/img/vancouver-maps.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  padding-top: 12rem;
}

.dashboardHeaderContainer .dashboardInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
}

.dashboardHeaderContainer .dashboardInfo .dashboardDescription {
  display: flex;
  align-items: center;
}

.dashboardHeaderContainer .dashboardInfo .dashboardSocials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dashboardHeaderContainer .dashboardInfo .dashboardSocials .socialIcons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboardHeaderContainer .dashboardInfo .dashboardSocials .socialIcons svg {
  height: 2rem;
  width: auto;
}

.dashboardHeaderContainer .dashboardInfo .profileImage {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: solid 0.25rem white;
  object-fit: cover;
  object-position: center center;
  margin-top: -4rem;
}

.dashboardHeaderContainer .dashboardInfo .dashboardNameContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 1rem;
}
.dashboardHeaderContainer
  .dashboardInfo
  .dashboardNameContainer
  .dashboardName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboardHeaderContainer
  .dashboardInfo
  .dashboardNameContainer
  .dashboardName
  svg {
  width: 1.5rem;
  height: auto;
  fill: var(--primaryColor);
}

.dashboardHeaderContainer .dashboardInfo .dashboardNameContainer h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}

.dashboardHeaderContainer .dashboardInfo .dashboardNameContainer p {
  font-size: 1rem;
  font-weight: 400;
  color: #858585;
}

.dashboardContainer {
  display: flex;
  width: 100%;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.dashboardContainer .sideNav {
  display: flex;
  flex-direction: column;
  width: 25%;
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  gap: 1rem;
  height: fit-content;
}

.dashboardContainer .sideNav h3 {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  margin-bottom: 0.5rem;
}

.dashboardContainer .sideNav .sideNavLinks {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dashboardContainer .sideNav .sideNavLinks .sideNavLink {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: #858585;
  gap: 0.75rem;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.dashboardContainer .sideNav .sideNavLinks .sideNavLink svg {
  width: 1.25rem;
  height: auto;
  fill: #858585;
}

.dashboardContainer .sideNav .sideNavLinks .sideNavLink.active {
  color: var(--primaryColor);
  background: rgba(73, 144, 226, 0.1);
  font-weight: 600;
}

.dashboardContainer .sideNav .sideNavLinks .sideNavLink.active svg {
  fill: var(--primaryColor);
}

.dashboardContainer .sideNav .sideNavLinks .sideNavLink:hover {
  opacity: 0.7;
}

.dashboardContainer .dashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 2.5rem;
  height: fit-content;
  margin-bottom: 0.75rem;
}

.dashboardContainer .addDiscussion {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 2.5rem;
  margin-bottom: 0.75rem;
  gap: 0.75rem;
  border-left: 0.5rem solid var(--primaryColor);
}

.dashboardContainer .addDiscussion .formHead {
  display: flex;
  gap: 0.75rem;
}

.dashboardContainer .addDiscussion img {
  border-radius: 0.5rem;
}

.dashboardContainer .addDiscussion input {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #f8f8f8;
  flex-grow: 1;
}

.dashboardContainer .addDiscussion textarea {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #f8f8f8;
  flex-grow: 1;
  font-size: 0.85rem;
  resize: vertical;
}

.dashboardContainer .dashboardContent h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: black;
  margin-bottom: 1rem;
}

.dashboardContainer .dashboardContent .description {
  font-size: 1rem;
  font-weight: 400;
  color: #858585;
  margin-bottom: 1.5rem;
}

.dashboardContainer .dashboardContent .flexHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dashboardContainer .dashboardContent .flexHeader h2 {
  margin-bottom: 0;
}

.dashboardContainer .discussionsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.dashboardContainer .discussionsContainer .discussionCard {
  background: white;
  padding: 2rem 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardHeader {
  display: flex;
  gap: 0.5rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardHeaderLeft
  img {
  border-radius: 0.5rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardHeaderRight
  button {
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardHeaderRight
  button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dashboardContainer .discussionsContainer .discussionCard .userCont {
  flex-grow: 1;
}

.userCont {
  position: relative;
}

.userInfo {
  display: none;
  position: absolute;
  max-width: 375px;
  bottom: 120%;
  left: 0;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  z-index: 1;
  color: #858585;
  font-weight: 400;
}

.userInfo .userHead {
  background-color: var(--primaryColor);
  color: white;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  gap: 0.65rem;
}

.userInfo .userHead img {
  border: 0.1rem solid rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
  height: 100%;
}

.userInfo .name {
  font-size: 1.25rem;
  font-weight: 600;
}

.userInfo .name svg {
  margin-left: 0.5rem;
}

.userInfo .userBadges {
  padding: 1rem 1rem 0;
  display: flex;
  gap: 0.5rem;
}

.userInfo .userSocials {
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
}

.userInfo .about {
  font-style: italic;
}

.dashboardContainer .discussionsContainer .discussionCard .userCont .name {
  width: fit-content;
  font-weight: 600;
  padding-bottom: 0.25rem;
}

.userCont .name:hover {
  cursor: pointer;
}

.userCont .name:hover .userInfo {
  display: block;
}

.dashboardContainer .discussionsContainer .discussionCard .userCont .date {
  font-size: 0.8rem;
  color: #858585;
}

.dashboardContainer .discussionsContainer .discussionCard .voteCont {
  display: flex;
  align-items: center;
}

.dashboardContainer .discussionsContainer .discussionCard .voteCont button {
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .voteCont
  button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dashboardContainer .discussionsContainer .discussionCard .voteCont p {
  color: #858585;
  font-size: 0.8rem;
  font-weight: 500;
}

.dashboardContainer .discussionsContainer .discussionCard .bodyHeader {
  display: flex;
  align-items: center;
}

.dashboardContainer .discussionsContainer .discussionCard .bodyHeader h3 {
  flex-grow: 1;
  font-weight: 600;
}

.dashboardContainer .discussionsContainer .discussionCard .bodyHeader p {
  color: var(--primaryColor);
  background-color: rgba(73, 144, 226, 0.1);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .bodyContent {
  padding-top: 0.5rem;
  color: #858585;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardFooter {
  background-color: #f8f8f8;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardFooter
  h4 {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardFooter
  h4:hover {
  text-decoration: underline;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .discussionCardFooter
  h4
  span {
  margin-left: 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
}

.dashboardContainer .discussionsContainer .discussionCard .comments {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .comment {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .commentHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboardContainer
  .discussionsContainer
  .discussionCard
  .commentHeaderLeft
  img {
  border-radius: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .comment .content {
  padding-top: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .addComment {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.dashboardContainer .discussionsContainer .discussionCard .addComment input {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: none;
}

@media screen and (max-width: 992px) {
  .dashboardHeaderContainer {
    padding-top: 10rem;
  }
  .dashboardHeaderContainer .dashboardInfo {
    padding: 0.5rem 1rem;
  }
  .dashboardHeaderContainer .dashboardInfo .profileImage {
    width: 8rem;
    height: 8rem;
    margin-top: -3rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardNameContainer h1 {
    font-size: 1.25rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardNameContainer p {
    font-size: 0.9rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardSocials .socialIcons svg {
    height: 1.75rem;
  }
  .dashboardContainer {
    gap: 1rem;
    margin-top: 1rem;
  }

  .dashboardContainer .sideNav {
    width: 30%;
    padding: 1rem 0.5rem;
  }
  .dashboardContainer .sideNav h3 {
    font-size: 0.8rem;
  }
  .dashboardContainer .sideNav .sideNavLinks .sideNavLink {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
  .dashboardContainer .dashboardContent {
    padding: 1.5rem 1.5rem;
    margin-bottom: 1rem;
  }
  .dashboardContainer .discussionsContainer .discussionCard {
    padding: 1.5rem 1.5rem;
  }
  .dashboardContainer .dashboardContent h2 {
    font-size: 1.5rem;
  }
  .dashboardContainer .dashboardContent .description {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .dashboardHeaderContainer {
    padding-top: 5rem;
  }
  .dashboardHeaderContainer .dashboardInfo {
    padding: 0.5rem 1rem;
  }
  .dashboardHeaderContainer .dashboardInfo .profileImage {
    width: 5rem;
    height: 5rem;
    margin-top: -2rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardNameContainer h1 {
    font-size: 1.1rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardNameContainer p {
    font-size: 0.8rem;
  }
  .dashboardHeaderContainer
    .dashboardInfo
    .dashboardNameContainer
    .dashboardName
    svg {
    width: 1.25rem;
  }
  .dashboardHeaderContainer .dashboardInfo .dashboardSocials {
    display: none;
  }
  .dashboardContainer .sideNav {
    display: none;
  }
  .dashboardContainer .dashboardContent {
    padding: 1rem 1rem;
  }
  .dashboardContainer .discussionsContainer .discussionCard {
    padding: 1rem 1rem;
  }
}
