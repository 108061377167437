@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primaryColor: #4990e2;
  --secondaryColor: #cede4f;
  --errorColor: #ff0033;
  --successColor: #00cc66;
  --infoColor: #0066ff;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background: #f8f8f8;
}

body:has(.mobileNavContainer.open) {
  overflow: hidden;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

input,
button,
textarea,
select {
  font-family: "Inter", sans-serif;
  outline: none;
}

input.searchBar {
  font-size: 0.9rem;
  color: black;
  padding: 0.5rem 1rem;
  border: solid 1px #b9b9b9;
  border-radius: 0.25rem;
  margin-left: 1.5rem;
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  margin: auto;
  z-index: 10;
}

.navContainer {
  background-color: white;
  min-height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 7rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.07);
}

.navLogo {
  display: flex;
  align-items: center;
}

.navLogo img {
  width: 100%;
  height: 2rem;
  object-fit: contain;
  object-position: center center;
}

.navLinksContainer {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.hamburgerMenu {
  display: none;
  cursor: pointer;
}

.navLink {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.navLink.active {
  color: var(--primaryColor);
  font-weight: 600;
}

.navLink:hover {
  opacity: 0.7;
}

.mobileNavContainer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  background-color: white;
  padding: 2rem 2.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  overflow-y: auto;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}

.mobileNavContainer.open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.mobileNavContainer .mobileNavHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.mobileNavContainer .mobileNavLinks {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin-top: 0;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup h3 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #858585;
  margin-bottom: 0.5rem;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup .sideNavLink {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: black;
  gap: 0.75rem;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup .sideNavLink svg {
  width: 1rem;
  height: auto;
  fill: black;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup .sideNavLink.active {
  color: var(--primaryColor);
  background: rgba(73, 144, 226, 0.1);
  font-weight: 600;
}

.mobileNavContainer .mobileNavLinks .navLinkGroup .sideNavLink.active svg {
  fill: var(--primaryColor);
}

.alertMessageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.alertMessageContainer p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  padding-left: 0.5rem;
  width: 100%;
}

.alertMessageContainer svg {
  width: 1.5rem;
  height: auto;
}

.alertMessageContainer.error {
  border: solid 1px var(--errorColor);
}

.alertMessageContainer.error p {
  color: var(--errorColor);
}

.alertMessageContainer.success {
  border: solid 1px var(--successColor);
}

.alertMessageContainer.success p {
  color: var(--successColor);
}

.alertMessageContainer.info {
  border: solid 1px var(--infoColor);
}

.alertMessageContainer.info p {
  color: var(--infoColor);
}

.btnPrimary {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 12px;
  border: solid 1px var(--primaryColor);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.btnPrimary.fill {
  color: white;
  background-color: var(--primaryColor);
}

.btnPrimary.outline {
  color: var(--primaryColor);
  background-color: transparent;
}

.btnPrimary.full {
  width: 100%;
}

.btnPrimary.disabled {
  background-color: #d9d9d9;
  border: solid 2px #d9d9d9;
  cursor: not-allowed;
}

.btnPrimary.fill:hover {
  opacity: 0.8;
}

.btnPrimary.outline:hover {
  background-color: var(--primaryColor);
  color: white;
}

.buttonSpinner {
  -ms-animation: btnSpinner 1s infinite linear;
  -moz-animation: btnSpinner 1s infinite linear;
  -webkit-animation: btnSpinner 1s infinite linear;
  -o-animation: btnSpinner 1s infinite linear;
  animation: btnSpinner 1s infinite linear;
}

#loadingText {
  color: #6e6e6e;
  animation: fadeIn 1s ease-in-out infinite alternate;
  -webkit-animation: fadeIn 1s ease-in-out infinite alternate;
  -moz-animation: fadeIn 1s ease-in-out infinite alternate;
  -ms-animation: fadeIn 1s ease-in-out infinite alternate;
  -o-animation: fadeIn 1s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes btnSpinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#wrapper {
  max-width: 1600px;
  margin: 15vh auto 3rem;
  padding: 0 7rem;
  min-height: 79vh;
}

.formBtnGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

@media screen and (max-width: 1200px) {
  .navContainer {
    padding: 1.5rem 5rem;
  }
  #wrapper {
    padding: 0 5rem;
  }
}

@media screen and (max-width: 992px) {
  .navContainer {
    padding: 1rem 3rem;
  }
  .navLogo img {
    height: 1.75rem;
  }
  input.searchBar {
    margin-left: 1rem;
    font-size: 0.8rem;
  }
  .navLinksContainer {
    gap: 1.5rem;
  }
  .navLink {
    font-size: 0.9rem;
  }
  #wrapper {
    padding: 0 3rem;
    margin: 10vh auto 3rem;
    min-height: 90vh;
  }
}

@media screen and (max-width: 768px) {
  .navContainer {
    padding: 1rem 2rem;
  }
  .navLogo img {
    height: 1.5rem;
  }
  input.searchBar {
    font-size: 0.8rem;
  }
  #wrapper {
    padding: 0 2rem;
  }
  .btnPrimary {
    font-size: 0.9rem;
  }
  .navLinksContainer .navLink {
    display: none;
  }
  .mobileNavContainer {
    display: flex;
    flex-direction: column;
  }
  .hamburgerMenu {
    display: block;
  }
  .navLink {
    font-size: 0.9rem;
  }
  .mobileNavContainer .navLogo img {
    width: 180px;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  input.searchBar {
    display: none;
  }
  .mobileNavContainer {
    width: 90%;
    padding: 2rem 1.5rem;
  }
  .mobileNavContainer .navLogo img {
    width: 150px;
  }
}
